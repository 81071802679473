import { CAlert, CAlertHeading } from '@coreui/react';
import React from 'react';
import { Trans } from 'react-i18next';

import { NotificationStatus } from '../../common/utils/notify';

import './Notification.scss';

interface NotificationProps {
  title?: string;
  message: string;
  status: NotificationStatus;
  onClose: (() => void) | undefined;
  className?: string;
}

const Notification: React.FC<NotificationProps> = ({ title, message, status, className, onClose }): React.ReactElement => {
  const notificationStatus = {
    [NotificationStatus.SUCCESS]: {
      className: 'success',
    },
    [NotificationStatus.INFO]: {
      className: 'info',
    },
    [NotificationStatus.WARNING]: {
      className: 'warning',
    },
    [NotificationStatus.ERROR]: {
      className: 'error',
    },
  };

  return (
    <CAlert color={notificationStatus[status].className} dismissible onClose={onClose}>
      {title && (
        <CAlertHeading>
          <h4>{title}</h4>
        </CAlertHeading>
      )}
      <Trans i18nKey={message} components={{ bold: <b /> }} />
    </CAlert>
  );
};

export default Notification;
