import { TableConfig } from '../services/types/common';
import { SortType } from './enum';

export const cardIssuers = [
  'BIDV',
  'VietinBank',
  'Vietcombank',
  'VPBank',
  'MB',
  'Techcombank',
  'Agribank',
  'ACB',
  'HDBank',
  'SHB',
  'Sacombank',
  'VBSP',
  'VIB',
  'MSB',
  'SCB',
  'VDB',
  'SeABank',
  'OCB',
  'Eximbank',
  'LienVietPostBank',
  'TPBank',
  'PVcomBank',
  'Woori',
  'BacABank',
  'HSBC',
  'SCBVL',
  'PBVN',
  'ABBANK',
  'SHBVN',
  'VietABank',
  'DongABank',
  'UOB',
  'Vietbank',
  'NamABank',
  'NCB',
  'OceanBank',
  'CIMB',
  'VietCapitalBan',
  'Kienlongbank',
  'IVB',
  'BAOVIETBank',
  'SAIGONBANK',
  'CoOpBank',
  'GPBank',
  'VRB',
  'CB',
  'PGBank',
  'ANZVL',
  'HLBVN',
];

export const initialTableConfig: TableConfig = {
  page: 1,
  pageSize: 10,
  searchValue: '',
  sortBy: 'updatedAt',
  sortDirection: SortType.DESC,
};

export const memberFieldToExports = [
  {
    id: 'id',
    label: 'ID',
    defaultSelected: true,
  },
  {
    id: 'employeeId',
    label: 'view.member.label.employeeId',
    defaultSelected: true,
  },
  {
    id: 'name',
    label: 'view.member.label.fullName',
    defaultSelected: true,
  },
  {
    id: 'citizenId',
    label: 'view.member.label.citizenId',
    defaultSelected: true,
  },
  {
    id: 'email',
    label: 'view.member.label.companyEmail',
    defaultSelected: true,
  },
  {
    id: 'personalEmail',
    label: 'view.member.label.personalEmail',
    defaultSelected: true,
  },
  {
    id: 'phone',
    label: 'view.member.label.phone',
    defaultSelected: true,
  },
  {
    id: 'gender',
    label: 'view.member.label.gender',
    defaultSelected: true,
  },
  {
    id: 'startDate',
    label: 'view.member.label.startDate',
    defaultSelected: true,
  },
  {
    id: 'birthDate',
    label: 'view.member.label.birthDate',
    defaultSelected: true,
  },
  {
    id: 'residentialAddress',
    label: 'view.member.label.residentialAddress',
    defaultSelected: true,
  },
  {
    id: 'permanentAddress',
    label: 'view.member.label.permanentAddress',
    defaultSelected: true,
  },

  {
    id: 'socialInsuranceId',
    label: 'view.member.label.insuranceId',
  },
  {
    id: 'isRegisterForFamilyDeduction',
    label: 'view.member.label.familyDeduction',
  },

  {
    id: 'netSalary',
    label: 'view.member.label.netSalary',
  },
  {
    id: 'grantSalary',
    label: 'view.member.label.benefitSalary',
  },
  {
    id: 'labourContractType',
    label: 'view.member.label.labourContractType',
  },
  {
    id: 'labourContractId',
    label: 'view.member.label.labourContractId',
  },
  {
    id: 'labourContractDate',
    label: 'view.member.label.labourContractDate',
  },
  {
    id: 'cardIssuer',
    label: 'view.member.label.cardIssuer',
  },
  {
    id: 'cardHolder',
    label: 'view.member.label.cardholderName',
  },
  {
    id: 'cardNumber',
    label: 'view.member.label.cardNumber',
  },
];

const { REACT_APP_IS_ADMIN } = process.env;

export const env = {
  isAdminApp: REACT_APP_IS_ADMIN ? JSON.parse(REACT_APP_IS_ADMIN) : false
}
