import { all } from 'redux-saga/effects';

import { authSaga } from '../../services/controllers/auth/AuthSagas';
import { userSaga } from '../../services/controllers/user/UserSagas';
import { groupSaga } from '../../services/controllers/group/GroupSagas';
import { timeOffSaga } from '../../services/controllers/time-off/TimeOffSagas';

export default function* rootSaga() {
  yield all([authSaga(), userSaga(), groupSaga(), timeOffSaga()]);
}
