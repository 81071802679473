import { createEntityAdapter, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { initialTableConfig } from '../../../common/constants';
import { BaseState } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/formatters';
import { UserDTO } from '../../types/apiType';
import { userActions } from './UserActions';

export interface UserState extends BaseState<UserDTO> {
  current?: string;
}

export const userAdapter = createEntityAdapter<UserDTO>();

const initialState: UserState = {
  ...userAdapter.getInitialState(),
  filters: {},
  currentFilter: filterKeyGenerator(initialTableConfig),
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    setFilters: (state, action) => {
      state.filters[action.payload.key] = pick(action.payload, 'ids', 'currentPage', 'total');
      state.currentFilter = action.payload.key;
    },
    setFilterKey: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userActions.getDetailSuccess, (state, action) => {
      userAdapter.upsertMany(state, action.payload.user);
    });
    builder.addCase(userActions.getListSuccess, (state, action) => {
      userAdapter.upsertMany(state, action.payload.user);
    });
    builder.addMatcher(isAnyOf(userActions.createSuccess, userActions.updateSuccess, userActions.deleteSuccess), (state, action) => {
      userAdapter.upsertMany(state, action.payload.user);
    });
  },
});

export const { setCurrentUser, setFilters, setFilterKey } = userSlice.actions;

export default userSlice.reducer;
