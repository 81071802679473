import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const storageKey = {
  TOKEN: 'token',
};

export const storage = {
  setToken: (token: string) => cookies.set(storageKey.TOKEN, token),

  getToken: () => cookies.get(storageKey.TOKEN),

  removeToken: () => cookies.remove(storageKey.TOKEN),

  setNextRequestOtpAt: (route: string, time: string) => cookies.set(route, time),

  getNextRequestOtpAt: (route: string) => cookies.get(route),

  setKeyValue: (key: string, value: unknown) => cookies.set(key, value),

  getKeyValue: (key: string) => cookies.get(key),

  removeStorageKey: (key: string) => cookies.remove(key),

  listenStorageChange: (callback: (params: { name: string; value?: string }) => void) => {
    cookies.addChangeListener(callback);
  },

  removeChangeListener: (callback: (params: { name: string; value?: string }) => void) => {
    cookies.removeChangeListener(callback);
  },

  get isLoggedIn() {
    return !!this.getToken();
  },
};
