import { identity, isEmpty, pickBy } from 'lodash';

export const filterKeyGenerator = (filters?: { [key: string]: any }) => {
  if (!filters) return 'empty';
  const pickFilters = pickBy(filters, identity);

  if (isEmpty(pickFilters)) return 'empty';

  const ordered = Object.keys(pickFilters)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = pickFilters[key];
      return obj;
    }, {});
  return JSON.stringify(ordered);
};

export const filterDecoded = (filterKey?: string) => {
  if (filterKey === 'empty' || !filterKey) return {};

  return JSON.parse(filterKey);
};
