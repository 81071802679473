import React, { useEffect } from 'react';
import { CButton, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { cilSettings, cilUser, cilAccountLogout } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { storage } from '../../../../common/utils/storage';
import { useAppDispatch, useAppSelector } from '../../../../common/redux/hooks';
import { userActions } from '../../../../services/controllers/user/UserActions';
import { selectCurrentUser } from '../../../../services/controllers/user/UserSelector';
import { routes } from '../../../../common/utils/routes';
import { env } from '../../../../common/constants';

const AppHeaderDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (storage.isLoggedIn && !currentUser) {
      dispatch(userActions.getDetail());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage.isLoggedIn]);

  const handleLogout = () => {
    storage.removeToken();
    window.location.reload();
  };

  const handleShowProfile = () => {
    const profilePath = generatePath(routes.EDIT_MEMBER, {
      id: currentUser?.id,
    });
    history.push(profilePath);
  };

  const shortenName = (name: string) => {
    const nameSplit = name.toUpperCase().split(' ');
    return `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}`;
  };

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        {currentUser?.name && (
          <CButton size="sm" shape="rounded-circle" color="dark">
            {shortenName(currentUser.name)}
          </CButton>
        )}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        {env.isAdminApp ? (
          <CDropdownItem component={CButton} onClick={handleShowProfile}>
            <CIcon icon={cilUser} className="me-2" />
            {t('view.header.label.profile')}
          </CDropdownItem>
        ) : null}
        <CDropdownItem component={CButton} onClick={() => history.push(routes.CHANGE_PASSWORD)}>
          <CIcon icon={cilSettings} className="me-2" />
          {t('view.header.label.changePassword')}
        </CDropdownItem>
        <CDropdownItem component={CButton} onClick={handleLogout}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          {t('view.header.label.logOut')}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
