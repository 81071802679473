import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../common/redux/store';

const selectCommon = (state: RootState) => state.common;

export const selectLoading = createSelector(selectCommon, (common) => common.loading);
export const selectModalStatus = createSelector(selectCommon, (common) => common.ui.dialog);

export const selectIsShowSidebar = createSelector(selectCommon, (common) => common.isShowSidebar);
