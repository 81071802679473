import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { initialTableConfig } from '../../../common/constants';
import { BaseState } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/formatters';
import { TimeOffDTO } from '../../types/apiType';
import { timeOffActions } from './TimeOffActions';

export interface TimeOffState extends BaseState<TimeOffDTO> {
  current?: string;
}

export const timeOffAdapter = createEntityAdapter<TimeOffDTO>();

const initialState: TimeOffState = {
  ...timeOffAdapter.getInitialState(),
  filters: {},
  currentFilter: filterKeyGenerator(initialTableConfig),
};

export const timeOffSlice = createSlice({
  name: 'timeOff',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters[action.payload.key] = pick(action.payload, 'ids', 'currentPage', 'total');
      state.currentFilter = action.payload.key;
    },
    setFilterKey: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(timeOffActions.getListSuccess, (state, action) => {
      timeOffAdapter.upsertMany(state, action.payload.timeOff);
    });
  },});

export const { setFilters, setFilterKey } = timeOffSlice.actions;

export default timeOffSlice.reducer;
