import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { configureStore, combineReducers, ThunkDispatch, AnyAction } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import rootSaga from './saga';
import authSlice from '../../services/controllers/auth/AuthSlice';
import commonSlice from '../../services/controllers/common/CommonSlice';
import userSlice from '../../services/controllers/user/UserSlice';
import groupSlice from '../../services/controllers/group/GroupSlice';
import timeOffSlide from '../../services/controllers/time-off/TimeoffSlide';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'user', 'common', 'auth', 'group', 'timeOff'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    router: connectRouter(history),
    auth: authSlice,
    common: commonSlice,
    user: userSlice,
    group: groupSlice,
    timeOff: timeOffSlide
  }),
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history), sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default store;
