import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import pick from 'lodash/pick';

import { initialTableConfig } from '../../../common/constants';
import { BaseState } from '../../../common/enum';
import { filterKeyGenerator } from '../../../common/utils/formatters';
import { GroupDTO, GroupUserDTO } from '../../types/apiType';
import { groupActions } from './GroupActions';

export interface GroupState extends BaseState<GroupDTO> {
  groupUser: GroupUserState;
  current?: string;
}
export interface GroupUserState extends BaseState<GroupUserDTO> {}

export const groupAdapter = createEntityAdapter<GroupDTO>();
export const groupUsersAdapter = createEntityAdapter<GroupUserDTO>();

const initialGroupUserState: GroupUserState = {
  ...groupUsersAdapter.getInitialState(),
  currentFilter: undefined,
  filters: {},
};

const initialState: GroupState = {
  ...groupAdapter.getInitialState(),
  filters: {},
  currentFilter: filterKeyGenerator(initialTableConfig),
  groupUser: initialGroupUserState,
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters[action.payload.key] = pick(action.payload, 'ids', 'currentPage', 'total');
      state.currentFilter = action.payload.key;
    },
    setFilterKey: (state, action) => {
      state.currentFilter = action.payload;
    },
    setGroupUserFilters: (state, action) => {
      state.groupUser.filters[action.payload.key] = pick(action.payload, 'ids', 'currentPage', 'total');
      state.groupUser.currentFilter = action.payload.key;
    },
    setGroupUsersFilterKey: (state, action) => {
      state.groupUser.currentFilter = action.payload;
    },
    setCurrentGroup: (state, action) => {
      state.current = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(groupActions.getDetailSuccess, (state, action) => {
      groupAdapter.upsertMany(state, action.payload.group);
    });
    builder.addCase(groupActions.getListSuccess, (state, action) => {
      groupAdapter.upsertMany(state, action.payload.group);
    });
    builder.addCase(groupActions.createSuccess, (state, action) => {
      groupAdapter.upsertMany(state, action.payload.group);
    });
    builder.addCase(groupActions.getListMembersSuccess, (state, action) => {
      groupUsersAdapter.upsertMany(state.groupUser, action.payload.groupUser);
    });
  },
});

export const { setFilters, setFilterKey, setGroupUserFilters, setGroupUsersFilterKey, setCurrentGroup } = groupSlice.actions;

export default groupSlice.reducer;
