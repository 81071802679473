import { AxiosInstance } from 'axios';

import { ActionFormUserParams, EditUserDetailParams, UserDTO, UsersTableResponse } from '../../types/apiType';
import { TableConfig } from '../../types/common';

export default class UserService {
  constructor(private axios: AxiosInstance) {}

  getUserDetail = async () => {
    const { data } = await this.axios.get('users/me');
    return data;
  };

  getUseSuggestion = async (searchKey: string): Promise<UserDTO[]> => {
    const { data } = await this.axios.get('users/autosuggest', { params: { searchKey } });
    return data;
  };

  create = async (params: ActionFormUserParams) => {
    const { data } = await this.axios.post('users', params);
    return data;
  };

  update = async (params: EditUserDetailParams) => {
    const { userId, values } = params;
    const { data } = await this.axios.put(`users/${userId}`, values);
    return data;
  };

  getList = async (params: TableConfig): Promise<UsersTableResponse> => {
    const { data } = await this.axios.get('users', { params });
    return data;
  };

  getDetailById = async (params: { userId: string }): Promise<UserDTO> => {
    const { data } = await this.axios.get(`users/${params.userId}`);
    return data;
  };

  delete = async (params: { userId: string }) => {
    const { userId } = params;
    const { data } = await this.axios.delete(`users/${userId}`);
    return data;
  };

  exportMember = async (values: { fields: string[] }): Promise<BlobPart> => {
    return this.axios.post(`users/export`, values, {
      responseType: 'arraybuffer'
    });
  };
}
