import React, { Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Redirect, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { lazy } from '@loadable/component';
import { ToastContainer } from 'react-toastify';
import { CSpinner } from '@coreui/react';

import store, { history, persistor } from './common/redux/store';
import { authGuard, isAdminApp, routes, unAuthGuard } from './common/utils/routes';

import PrivateRoute from './components/Route/PrivateRoute';
import AppSidebar from './components/AppSidebar/AppSidebar';
import AppHeader from './components/AppHeader/AppHeader';
import { env } from './common/constants';

// Scenes
const LoginView = lazy(() => import('./views/login/LoginView'));
const MembersView = lazy(() => import('./views/members/MembersView'));
const MemberForm = lazy(() => import('./views/members/MemberForm'));
const ChangePasswordView = lazy(() => import('./views/change-password/ChangePasswordView'));
const GroupsView = lazy(() => import('./views/groups/GroupsView'));
const GroupDetailView = lazy(() => import('./views/group-detail/GroupDetail'));
const DayOffView = lazy(() => import('./views/dayoff/DayOffView'));

const DashboardRouter = () => {
  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="flex-grow-1 px-1 pb-3">
          <Suspense fallback={<CSpinner />}>
            <Switch>
              <PrivateRoute path={routes.MEMBER} component={MembersView} guards={[authGuard, isAdminApp]} />
              <PrivateRoute path={routes.GROUP} component={GroupsView} guards={[authGuard, isAdminApp]} />
              <PrivateRoute path={routes.GROUP_DETAIL} component={GroupDetailView} guards={[authGuard, isAdminApp]} />
              <PrivateRoute path={routes.ADD_MEMBER} component={MemberForm} guards={[authGuard, isAdminApp]} />
              <PrivateRoute path={routes.EDIT_MEMBER} component={MemberForm} guards={[authGuard, isAdminApp]} />
              <PrivateRoute path={routes.CHANGE_PASSWORD} component={ChangePasswordView} guards={[authGuard]} />
              <PrivateRoute path={routes.DAY_OFF} component={DayOffView} guards={[authGuard]} />

              <Redirect to={env.isAdminApp ? routes.MEMBER : routes.DAY_OFF} />
            </Switch>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer />
        <React.Suspense fallback={null}>
          <ConnectedRouter history={history}>
            <Switch>
              <PrivateRoute exact path={routes.LOGIN} component={LoginView} guards={[unAuthGuard]} />
              <PrivateRoute path={routes.DEFAULT} component={DashboardRouter} guards={[authGuard]} />
            </Switch>
          </ConnectedRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
