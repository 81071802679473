import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CommonState {
  loading: boolean;
  ui: {
    dialog: {
      [name: string]: boolean;
    };
    dialogState: any;
  };
  isShowSidebar: boolean;
}

const initialState: CommonState = {
  loading: false,
  ui: { dialog: {}, dialogState: undefined },
  isShowSidebar: true,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    toggleModals: (state, action: PayloadAction<string>) => {
      state.ui.dialog = { ...state.ui.dialog, [action.payload]: !state.ui.dialog[action.payload] };
    },
    toggleSidebar: (state) => {
      state.isShowSidebar = !state.isShowSidebar;
    },
  },
});

export const { setLoading, toggleModals, toggleSidebar } = commonSlice.actions;

export default commonSlice.reducer;
