import axios from 'axios';
import { env } from '../common/constants';
import { storage } from '../common/utils/storage';

const { REACT_APP_API_ENDPOINT, API_TIME_OUT } = process.env;

const ApiClient = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  timeout: (API_TIME_OUT && parseInt(API_TIME_OUT, 10)) || 15000,
});

ApiClient.interceptors.request.use(
  (config: any) => {
    const authorizationData = storage.getToken();
    if (authorizationData) {
      config.headers.Authorization = `Bearer ${authorizationData}`;
    }
    config.headers['client-app'] = env.isAdminApp ? 'admin-web' : 'employee-web';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    if (error.response.status === 401 || error.response.status === 403) {
      storage.removeToken();
      window.location.reload();
    } else {
      throw error
    }
  },
);

export default ApiClient;
