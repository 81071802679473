import { AxiosInstance } from 'axios';

import {
  CreateGroupParams,
  GroupDTO,
  GroupsTableResponse,
  GetListMembersParams,
  GroupUsersTableResponse,
  GroupUserDTO,
  UpdateMembersParams,
  UpdateGroupParams,
} from '../../types/apiType';
import { TableConfig } from '../../types/common';

export default class GroupService {
  constructor(private axios: AxiosInstance) {}

  getDetailById = async (params: { groupId: string }): Promise<GroupDTO> => {
    const { data } = await this.axios.get(`groups/${params.groupId}`);
    return data;
  };

  create = async (params: CreateGroupParams) => {
    const { data } = await this.axios.post('groups', params);
    return data;
  };

  update = async (params: UpdateGroupParams) => {
    const { groupId, name } = params;
    const { data } = await this.axios.put(`groups/${groupId}`, { name });
    return data;
  };

  delete = async (params: { groupId: string }) => {
    const { groupId } = params;
    const { data } = await this.axios.delete(`groups/${groupId}`);
    return data;
  };

  getList = async (params: TableConfig): Promise<GroupsTableResponse> => {
    const { data } = await this.axios.get('groups', { params });
    return data;
  };

  getListMembers = async (params: GetListMembersParams): Promise<GroupUsersTableResponse> => {
    const { groupId, searchParams } = params;
    const { data } = await this.axios.get(`groups/${groupId}/members`, { params: searchParams });
    return data;
  };

  updateMembers = async (params: UpdateMembersParams): Promise<GroupUserDTO[]> => {
    const { groupId, userIds, type } = params;

    const { data } = await this.axios.post(`groups/${groupId}/update`, { userIds, type });
    return data;
  };
}
