import { createSelector } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import { RootState } from '../../../common/redux/store';
import { UserDTO } from '../../types/apiType';

export const selectUser = (state: RootState) => state.user;

export const selectCurrentUserId = (state: RootState) => state.user.current;
const usersFilter = (state: RootState) => state.user.filters;
const usersEntities = (state: RootState) => state.user.entities;
export const usersCurrentFilter = (state: RootState) => state.user.currentFilter;

export const selectCurrentUser = createSelector(selectUser, selectCurrentUserId, (user, currentId) =>
  currentId ? user.entities[currentId] : undefined,
);
export const selectUsersCurrentFilter = createSelector(usersFilter, usersCurrentFilter, (filters, currentFilter) =>
  currentFilter ? filters[currentFilter] : undefined,
);

export const selectUsersFilterValue = createSelector(usersEntities, selectUsersCurrentFilter, (entities, currentFilterValue) => {
  return currentFilterValue && !isEmpty(entities)
    ? currentFilterValue.ids.map((id) => entities[id] as UserDTO).filter((i) => !i.isDeleted || !!i)
    : [];
});

export const selectUserById = (id?: string) =>
  createSelector(selectUser, ({ entities }) => {
    return id ? entities[id] : null;
  });
