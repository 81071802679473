import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { RootState } from '../../../common/redux/store';
import { GroupDTO } from '../../types/apiType';

export const selectGroup = (state: RootState) => state.group;

const groupsFilter = (state: RootState) => state.group.filters;
const groupsEntities = (state: RootState) => state.group.entities;
export const groupsCurrentFilter = (state: RootState) => state.group.currentFilter;

export const selectGroupsCurrentFilter = createSelector(groupsFilter, groupsCurrentFilter, (filters, currentFilter) =>
  currentFilter ? filters[currentFilter] : undefined,
);

export const selectGroupsFilterValue = createSelector(groupsEntities, selectGroupsCurrentFilter, (entities, currentFilterValue) => {
  return currentFilterValue && !isEmpty(entities) ? currentFilterValue.ids.map((id) => entities[id] as GroupDTO).filter((i) => !!i) : [];
});

export const selectGroupById = (id?: string) =>
  createSelector(selectGroup, ({ entities }) => {
    return id ? entities[id] : null;
  });
