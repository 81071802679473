import { AxiosInstance } from 'axios';
import { TableConfig } from '../../types/common';

export default class TimeOffService {
  constructor(private axios: AxiosInstance) {}

  getList = async (params: TableConfig): Promise<any> => {
    const { data } = await this.axios.get('time-off', { params });
    return data;
  };
  createTimeOffRequest = async (params: any) => {
    const { data } = await this.axios.post('time-off', params);
    return data;
  };
  deleteTimeOff = async (id: number) => {
    const { data } = await this.axios.delete(`time-off/${id}`);
    return data;
  };
}
