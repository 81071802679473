import { Dictionary, EntityId } from '@reduxjs/toolkit';

export interface BaseState<S = any> {
  ids: EntityId[];
  entities: Dictionary<S>;
  filters: {
    // filter value history
    [key: string]: {
      ids: string[];
      total: number;
      currentPage: number;
    };
  };
  currentFilter?: string; // current filter params
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum TimeOffOfTheDay {
  NoOff = 0,
  HalfDay = 0.5,
  FullDay = 1,
}

export enum TimeOffRequestStatus {
  APPROVED = 'approved',
  UNAPPROVED = 'unapproved',
  PENDING = 'pending',
}