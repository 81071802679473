import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LoginFailureResponse } from './AuthActionTypes';

interface AuthState {
  loginMessage?: string;
}

const initialState: AuthState = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFail: (state, action: PayloadAction<LoginFailureResponse>) => {
      state.loginMessage = action.payload.loginMessage;
    },
  },
});

export const { loginFail } = authSlice.actions;

export default authSlice.reducer;
