import { get } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from '@reduxjs/toolkit';

import api from '../../apiServices';
import { groupEntity, groupUserEntity } from '../schemas';
import { groupActions } from './GroupActions';
import { GroupDTO, GroupNormalized, GroupUserDTO, GroupUserNormalized } from '../../types/apiType';
import { normalized } from '../../../common/utils/normalized';
import { setCurrentGroup, setFilters, setGroupUserFilters } from './GroupSlice';
import { setLoading } from '../common/CommonSlice';
import { CommonGenerator, TableResponse } from '../../types/common';
import { notify } from '../../../common/utils/notify';
import { filterDecoded, filterKeyGenerator } from '../../../common/utils/formatters';
import { groupUsersCurrentFilter } from './GroupUserSelector';
import { groupsCurrentFilter } from './GroupSelector';

function* getGroupDetailSaga(action: AnyAction): CommonGenerator<GroupDTO, void> | void {
  try {
    let data;
    if (action.payload) {
      data = yield call(api.group.getDetailById, action.payload);
    }
    if (data) {
      yield put(groupActions.getDetailSuccess(normalized<GroupNormalized>(data, groupEntity).entities));
      yield put(setCurrentGroup(data.id));
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListGroupSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data: TableResponse<GroupDTO> = yield call(api.group.getList, action.payload);
    if (data.total) {
      const filterKey = filterKeyGenerator(action.payload);
      yield put(groupActions.getListSuccess(normalized<GroupNormalized>(data.list, [groupEntity]).entities));
      const currentIds: any[] = [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(data.list, [groupEntity]).result),
          total: data.total,
          currentPage: data.current_page,
        }),
      );
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* createGroupSaga(action: AnyAction): CommonGenerator<GroupDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.group.create, action.payload);
    if (data) {
      yield put(groupActions.createSuccess(normalized<GroupNormalized>(data, groupEntity).entities));
      const groupsCurrentFilterKey = yield select(groupsCurrentFilter);
      const filter = filterDecoded(groupsCurrentFilterKey);
      yield put(groupActions.getList(filter));
      yield notify.success('notify.success.createNewGroup');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateGroupSaga(action: AnyAction): CommonGenerator<GroupDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.group.update, action.payload);
    if (data) {
      const groupsCurrentFilterKey = yield select(groupsCurrentFilter);
      const filter = filterDecoded(groupsCurrentFilterKey);
      yield put(groupActions.getList(filter));
      yield notify.success('notify.success.updateGroup');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteGroupSaga(action: AnyAction): CommonGenerator<GroupDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.group.delete, action.payload);
    if (data) {
      yield put(groupActions.deleteSuccess(normalized<GroupNormalized>(data, groupEntity).entities));
      const groupsCurrentFilterKey = yield select(groupsCurrentFilter);
      const filter = filterDecoded(groupsCurrentFilterKey);
      yield put(groupActions.getList(filter));
      yield notify.success('notify.success.deleteGroup');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* getListMemberSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data: TableResponse<GroupUserDTO> = yield call(api.group.getListMembers, action.payload);
    if (data.total) {
      const filterKey = filterKeyGenerator(action.payload);
      yield put(groupActions.getListMembersSuccess(normalized<GroupUserNormalized>(data.list, [groupUserEntity]).entities));
      const currentIds: any[] = [];
      yield put(
        setGroupUserFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(data.list, [groupUserEntity]).result),
          total: data.total,
          currentPage: data.current_page,
        }),
      );
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateMembersSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data = yield call(api.group.updateMembers, action.payload);
    if (data.success) {
      const groupUsersCurrentFilterKey = yield select(groupUsersCurrentFilter);
      yield put(groupActions.getListMembers(filterDecoded(groupUsersCurrentFilterKey)));
      yield notify.success(`notify.success.${action.payload.type}MemberGroup`);
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* groupSaga() {
  yield all([
    takeLatest(groupActions.getDetail, getGroupDetailSaga),
    takeLatest(groupActions.getList, getListGroupSaga),
    takeLatest(groupActions.create, createGroupSaga),
    takeLatest(groupActions.update, updateGroupSaga),
    takeLatest(groupActions.delete, deleteGroupSaga),
    takeLatest(groupActions.getListMembers, getListMemberSaga),
    takeLatest(groupActions.updateMembers, updateMembersSaga),
  ]);
}
