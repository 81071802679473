import { createAction } from '@reduxjs/toolkit';
import { ActionFormUserParams, EditUserDetailParams, UserNormalized } from '../../types/apiType';
import { TableConfig } from '../../types/common';

export const userActions = {
  getDetail: createAction<{ userId: string } | undefined>('user/GET_DETAIL'),
  getDetailSuccess: createAction<UserNormalized>('user/GET_DETAIL_SUCCESS'),

  getList: createAction<Partial<TableConfig>>('user/GET_LIST'),
  getListSuccess: createAction<UserNormalized>('user/GET_LIST_SUCCESS'),

  create: createAction<Partial<ActionFormUserParams>>('user/CREATE'),
  createSuccess: createAction<UserNormalized>('user/CREATE_SUCCESS'),

  update: createAction<EditUserDetailParams>('user/UPDATE'),
  updateSuccess: createAction<UserNormalized>('user/UPDATE_SUCCESS'),

  delete: createAction<{ userId: string }>('user/DELETE'),
  deleteSuccess: createAction<UserNormalized>('user/DELETE_SUCCESS'),

  getDetailById: createAction<{ userId: string }>('user/GET_DETAIL_BY_ID'),
  getDetailByIdSuccess: createAction<UserNormalized>('user/GET_DETAIL_BY_ID_SUCCESS'),
};
