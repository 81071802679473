import { AxiosInstance, AxiosResponse } from 'axios';
import { ResetPasswordParams } from '../../types/apiType';
import { ChangePasswordResponse } from './AuthActionTypes';

export interface VerifyInviteForm {
  username: string;
  password: string;
  confirmationPassword: string;
  token: string;
}

export interface ResetPasswordForm {
  token: string;
  password: string;
  confirmationPassword: string;
}

export default class AuthService {
  constructor(private axios: AxiosInstance) {}

  login = async ({ email, password }: { email: string; password: string }): Promise<AxiosResponse<null>> => {
    const { data } = await this.axios.post('auth/login', { email, password });
    return data;
  };

  resetPassword = async ({ newPassword, currentPassword }: ResetPasswordParams): Promise<AxiosResponse<ChangePasswordResponse>> => {
    const { data } = await this.axios.put('auth/reset-password', {
      newPassword,
      currentPassword,
    });
    return data;
  };

  changePassword = async (userId: string): Promise<AxiosResponse<ChangePasswordResponse>> => {
    const { data } = await this.axios.post('auth/change-password', {
      userId,
    });
    return data;
  };
}
