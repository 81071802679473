import { createAction } from '@reduxjs/toolkit';

import {
  UpdateMembersParams,
  CreateGroupParams,
  GetListMembersParams,
  GroupNormalized,
  GroupUserNormalized,
  UpdateGroupParams,
} from '../../types/apiType';
import { TableConfig } from '../../types/common';

export const groupActions = {
  getDetail: createAction<{ groupId: string } | undefined>('group/GET_DETAIL'),
  getDetailSuccess: createAction<GroupNormalized>('group/GET_DETAIL_SUCCESS'),

  getList: createAction<Partial<TableConfig>>('group/GET_LIST'),
  getListSuccess: createAction<GroupNormalized>('group/GET_LIST_SUCCESS'),

  getListMembers: createAction<GetListMembersParams>('group/GET_LIST_MEMBERS'),
  getListMembersSuccess: createAction<GroupUserNormalized>('group/GET_LIST_MEMBERS_SUCCESS'),

  create: createAction<CreateGroupParams>('group/CREATE'),
  createSuccess: createAction<GroupNormalized>('group/CREATE_SUCCESS'),

  update: createAction<UpdateGroupParams>('group/UPDATE'),

  delete: createAction<{ groupId: number }>('group/DELETE'),
  deleteSuccess: createAction<GroupNormalized>('group/DELETE_SUCCESS'),

  updateMembers: createAction<UpdateMembersParams>('group/UPDATE_MEMBER'),
  updateMembersSuccess: createAction('group/UPDATE_MEMBER_SUCCESS'),
};
