import { TimeOffDTO, NormalizedDTO } from './../../types/apiType';
import { TableResponse } from './../../types/common';
import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from '@reduxjs/toolkit';

import api from '../../apiServices';
import { timeOffEntity } from '../schemas';
import { timeOffActions } from './TimeOffActions';
import { setLoading } from '../common/CommonSlice';
import { notify } from '../../../common/utils/notify';
import { filterKeyGenerator } from '../../../common/utils/formatters';
import { normalized } from '../../../common/utils/normalized';
import { setFilters } from './TimeoffSlide';

function* getListTimeOff(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data: TableResponse<TimeOffDTO> = yield call(api.timeOff.getList, action.payload);
    if (data.total) {
      const filterKey = filterKeyGenerator(action.payload);
      yield put(timeOffActions.getListSuccess(normalized<{timeOff: NormalizedDTO<TimeOffDTO>}>(data.list, [timeOffEntity]).entities));
      const currentIds: string[] = [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(data.list, [timeOffEntity]).result),
          total: data.total,
          currentPage: data.current_page,
        }),
      );
    }
  } catch (error) {
    console.log({ error})
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* timeOffSaga() {
  yield all([takeLatest(timeOffActions.getList, getListTimeOff)]);
}
