import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { RootState } from '../../../common/redux/store';
import { GroupUserDTO } from '../../types/apiType';
import { selectGroup } from './GroupSelector';

export const selectCurrentGroup = (state: RootState) => state.group.current;

const groupUsersFilter = (state: RootState) => state.group.groupUser.filters;
export const groupUsersCurrentFilter = (state: RootState) => state.group.groupUser.currentFilter;

export const selectGroupUsersCurrentFilter = createSelector(groupUsersFilter, groupUsersCurrentFilter, (filters, currentFilter) =>
  currentFilter ? filters[currentFilter] : undefined,
);

export const selectGroupUsersFilterValue = createSelector(
  selectGroup,
  selectGroupUsersCurrentFilter,
  ({ current, groupUser }, currentFilterValue) => {
    return current && currentFilterValue && !isEmpty(groupUser.entities)
      ? currentFilterValue.ids.map((id) => groupUser.entities[id] as GroupUserDTO).filter((i) => !!i && i.groupId === parseInt(current))
      : [];
  },
);
