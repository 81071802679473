import React from 'react';
import { CImage, CNavItem, CSidebar, CSidebarBrand, CSidebarNav } from '@coreui/react';
import { Link } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import { cilClock, cilContact, cilPeople } from '@coreui/icons';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../common/redux/hooks';
import { selectIsShowSidebar } from '../../services/controllers/common/CommonSelector';
import { routes } from '../../common/utils/routes';
import logo from '../../assets/logo/image.png';
import { env } from '../../common/constants';

const AppSidebar = () => {
  const { t } = useTranslation();

  const isShowSidebar = useAppSelector(selectIsShowSidebar);

  return (
    <CSidebar position="fixed" visible={isShowSidebar} className="sidebar-dark bg-dark-gradient">
      <CSidebarBrand className="justify-content-start">
        <CImage src={logo} width={40} className="ms-3 me-2 rounded" />
        {t('common.title.site')}
      </CSidebarBrand>
      <CSidebarNav>
        {env.isAdminApp ? (
          <>
            <CNavItem component={Link} to={routes.MEMBER}>
              <CIcon customClassName="nav-icon" icon={cilContact} />
              {t('view.sidebar.label.member')}
            </CNavItem>
            <CNavItem component={Link} to={routes.GROUP}>
              <CIcon customClassName="nav-icon" icon={cilPeople} />
              {t('view.sidebar.label.group')}
            </CNavItem>
          </>
        ) : null}

        <CNavItem component={Link} to={routes.DAY_OFF}>
          <CIcon customClassName="nav-icon" icon={cilClock} />
          {t('view.sidebar.label.timeoff')}
        </CNavItem>
      </CSidebarNav>
      <div className="copyright">© {new Date().getFullYear()} PoliteTech</div>
    </CSidebar>
  );
};

export default AppSidebar;
