import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../apiServices';
import { userEntity } from '../schemas';
import { userActions } from './UserActions';
import { UserDTO, UserNormalized } from '../../types/apiType';
import { normalized } from '../../../common/utils/normalized';
import { setCurrentUser, setFilters } from './UserSlice';
import { AnyAction } from '@reduxjs/toolkit';
import { setLoading } from '../common/CommonSlice';
import { CommonGenerator, TableResponse } from '../../types/common';
import { notify } from '../../../common/utils/notify';
import { filterKeyGenerator } from '../../../common/utils/formatters';

function* getUserDetailSaga(action: AnyAction): CommonGenerator<UserDTO, void> | void {
  try {
    let data;
    if (action.payload) {
      data = yield call(api.user.getDetailById, action.payload);
    } else {
      data = yield call(api.user.getUserDetail);
    }
    if (data) {
      yield put(userActions.getDetailSuccess(normalized<UserNormalized>(data, userEntity).entities));
      if (!action.payload) {
        yield put(setCurrentUser(data.id));
      }
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  }
}

function* getListUserSaga(action: AnyAction): any {
  try {
    yield put(setLoading(true));
    const data: TableResponse<UserDTO> = yield call(api.user.getList, action.payload);
    if (data.total) {
      const filterKey = filterKeyGenerator(action.payload);
      yield put(userActions.getListSuccess(normalized<UserNormalized>(data.list, [userEntity]).entities));
      const currentIds: any[] = [];
      yield put(
        setFilters({
          key: filterKey,
          ids: currentIds.concat(normalized(data.list, [userEntity]).result),
          total: data.total,
          currentPage: data.current_page,
        }),
      );
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* createUserSaga(action: AnyAction): CommonGenerator<UserDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.user.create, action.payload);
    if (data) {
      yield put(userActions.createSuccess(normalized<UserNormalized>(data, userEntity).entities));
      yield notify.success('notify.success.createNewUser');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* updateUserSaga(action: AnyAction): CommonGenerator<UserDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.user.update, action.payload);
    if (data) {
      yield put(userActions.updateSuccess(normalized<UserNormalized>(data, userEntity).entities));
      notify.success('notify.success.updateUserSuccess');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

function* deleteUserSaga(action: AnyAction): CommonGenerator<UserDTO, void> | void {
  try {
    yield put(setLoading(true));
    const data = yield call(api.user.delete, action.payload);
    if (data) {
      yield put(userActions.deleteSuccess(normalized<UserNormalized>(data, userEntity).entities));
      notify.success('notify.success.deleteUserSuccess');
    }
  } catch (error) {
    const message = get(error, 'response.data.message');
    notify.error(message);
  } finally {
    yield put(setLoading(false));
  }
}

export function* userSaga() {
  yield all([
    takeLatest(userActions.getDetail, getUserDetailSaga),
    takeLatest(userActions.getList, getListUserSaga),
    takeLatest(userActions.create, createUserSaga),
    takeLatest(userActions.update, updateUserSaga),
    takeLatest(userActions.delete, deleteUserSaga),
  ]);
}
